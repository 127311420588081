import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-bootstrap";
import { newschannel } from "../jsonconstant/index";
import { useState } from "react";
const NewsPage = () => {
  const navigate = useNavigate();
  const currentDate = new Date();
  
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const currentMonth = monthNames[currentDate.getMonth()];
  const currentDay = currentDate.getDate();
  const currentYear = currentDate.getFullYear();

  // Format the date as "Month-June-Day"
  const formattedDate = `${currentYear}-${currentMonth}-${currentDay}`;

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredNews, setFilteredNews] = useState(newschannel);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);

    const filtered =
    e.target.value==="" ? newschannel : newschannel.filter((item) =>
      item.titleurl.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredNews(filtered); 
    
  };
  
  return (
    <>
      <Helmet>
        <body style={` background: #F5F5F5;`}></body>
      </Helmet>

      <Container fluid className="container-border-b p-0">
        <Navbar
          collapseOnSelect
          className=" bg-white w-100 pb-0 mb-0"
          style={{ zIndex: "1" }}
        >
          <Container fluid className="ms-0 me-0">
            <Navbar.Brand onClick={() => navigate("/SellingSide")}>
              <p className="logo m-0 p-0">SELECTEZI</p>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                <Nav.Link
                  eventKey={2}
                  href=""
                  target="_blank"
                  onClick={() => navigate("/wellcome")}
                >
                  <button className="signup-btn">Sign up</button>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>

      <Container className="p-lg-0">
        <div className="section-one m-auto p-4 mt-5 bg-white">
          <p className="mb-0 section-para">
          A source of business to business, business to consumer and consumer to consumer market platforms 
          </p>
        </div>
      </Container>
      <Container className="p-lg-0">
<div className="bg-white section-two mt-4 p-4">
<Row >
          <Col lg="6">
            <p className="mb-0 news-heading">Todays News</p>
          </Col>
          <Col lg="6">
            <div className="input-group border">
              <input
                class="form-control "
                type="text"
                placeholder="Search Here..."
                id="example-search-input"
                value={searchQuery}
                onChange={(e)=>handleSearchChange(e)}
                
              />
              {/* <span class="input-group-append">
                <button
                  class="btn search-btn border-start-0 border  ms-n3"
                  type="button"
                  onClick={handleSearchClick}
                >
                  Search 
                </button>
              </span> */}
            </div>
          </Col>
        </Row>
</div>
       
      </Container>

      <Container className="mt-2 p-lg-0">
        <div className="news-height pb-5">
        <Row className="justify-content-around p-5">
          {/* {searchQuery?.length>0 ? "" : ( <Col lg="5" className="cards-bg mt-3">
            <a href="https://dailyshamal.com.pk/" target="_blank">
              <div className="img-div d-flex justify-content-center p-5 pb-2">
                <img src={aryimage} alt="ary-news" className="img-style" />
              </div>

              <h4 className="text-white">WWW.ARYNEWS.COM</h4>
              <p className="text-white mt-1 cards-small-font">
                click to read complete new on our website.
              </p>
            </a>
          </Col>)

          } */}
         
          {filteredNews?.map((item) => (
            <Col lg="3" md="5" className="mt-3  p-0">
              
                <div class="card  custom-card-width custom-card">
                <a href={item?.link} target="_blank">
                  <div className="card-div-img ">
                    <img src={item?.img} style={{height: `${item?.height}`}} class="card-img-top p-5 pt-3" alt="" />
                  </div>

                  <div class="card-body pt-2 pb-2 ps-3 pe-3">
                    <h6 class="card-title mb-2">{item?.titleurl}</h6>
                    <p class="card-text">{item?.name}</p>
                  </div>

                  <p class="card-date  ps-3 pt-1">{formattedDate}</p>
                  </a>
                </div>
            
            </Col>
          ))}


{/* {searchQuery?.length>0 ? "" : ( <Col lg="5" className="cards-bg mt-3">
            <a href="https://dailyshamal.com.pk/" target="_blank">
              <div className="img-div d-flex justify-content-center p-5 pb-2">
                <img src={aryimage} alt="ary-news" className="img-style" />
              </div>

              <h4 className="text-white">WWW.ARYNEWS.COM</h4>
              <p className="text-white mt-1 cards-small-font">
                click to read complete new on our website.
              </p>
            </a>
          </Col>)} */}


        </Row>

        
        {/* {searchQuery?.length>0 ? "" : ( <Col lg="5" className="cards-bg mt-3">
            <a href="https://dailyshamal.com.pk/" target="_blank">
              <div className="img-div d-flex justify-content-center p-5 pb-2">
                <img src={aryimage} alt="ary-news" className="img-style" />
              </div>

              <h4 className="text-white">WWW.ARYNEWS.COM</h4>
              <p className="text-white mt-1 cards-small-font">
                click to read complete new on our website.
              </p>
            </a>
          </Col>) } */}


          {/* {filteredNews?.map((item) => (
            <Col lg="3" md="5" className="mt-3 custom-card p-0">
              <a href={item?.link} target="_blank">
                <div class="card custom-card-width">
                  <div className="card-div-img ">
                    <img src={item?.img} class="card-img-top p-5 pt-3" alt="" />
                  </div>

                  <div class="card-body pt-2 pb-2 ps-3 pe-3">
                    <h6 class="card-title mb-2">{item?.titleurl}</h6>
                    <p class="card-text">{item?.name}</p>
                  </div>
                  <p class="card-date  ps-3 pt-1">{formattedDate}</p>
                </div>
              </a>
            </Col>
          ))} */}


           {/* {searchQuery?.length>0 ? "" : ( <Col lg="5" className="cards-bg mt-3">
            <a href="https://dailyshamal.com.pk/" target="_blank">
              <div className="img-div d-flex justify-content-center p-5 pb-2">
                <img src={aryimage} alt="ary-news" className="img-style" />
              </div>

              <h4 className="text-white">WWW.ARYNEWS.COM</h4>
              <p className="text-white mt-1 cards-small-font">
                click to read complete new on our website.
              </p>
            </a>
          </Col>)} */}
     

        </div>

        
      </Container>
    </>
  );
};

export default NewsPage;
