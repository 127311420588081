import {  useState } from "react";
import CountdownTimer from "../Countdown";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { TiSocialLinkedinCircular } from "react-icons/ti";
import { GiEarthAfricaEurope } from "react-icons/gi";
import { Container, Row, Carousel, Col } from "react-bootstrap";
import { videoprop } from "../jsonconstant";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import "./style.css";


const Wellcompage = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);
const navigate=useNavigate();
  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  return (
    <>
      <Row>
        <Container fluid>
          <Carousel>
            {!videoLoaded && <div className="loader">Loading.....</div>}
            {videoprop?.map((e) => {
              return (
                <Carousel.Item key={e.id}>
                  <div className="position-relative">
                    <div className="text-p-a">
                      <div className="d-flex text-white">
                        <p className="mb-0 para-style">S</p> &nbsp;&nbsp;
                        <p className="mb-0 para-sub-style mt-lg-3">
                          Safe and Secure Business Environment
                        </p>
                      </div>

                      <div className="d-flex text-white">
                        <p className="mb-0 para-style">E</p> &nbsp;&nbsp;
                        <p className="mb-0 para-sub-style mt-lg-3">
                          Empower to the business resources
                        </p>
                      </div>

                      <div className="d-flex text-white">
                        <p className="mb-0 para-style">L</p> &nbsp;&nbsp;
                        <p className="mb-0 para-sub-style mt-lg-3">
                          Leads toward Object
                        </p>
                      </div>

                      <div className="d-flex text-white">
                        <p className="mb-0 para-style">E</p> &nbsp;&nbsp;
                        <p className="mb-0 para-sub-style mt-lg-3">
                          Effective to grow your business Network
                        </p>
                      </div>

                      <div className="d-flex text-white">
                        <p className="mb-0 para-style">C</p> &nbsp;&nbsp;
                        <p className="mb-0 para-sub-style mt-lg-3">
                          Creativity & support for your business pursuit
                        </p>
                      </div>

                      <div className="d-flex text-white">
                        <p className="mb-0 para-style">T</p> &nbsp;&nbsp;
                        <p className="mb-0 para-sub-style mt-lg-3">
                          Terminology to sources out Your best Professional
                          Talent
                        </p>
                      </div>

                      <div className="d-flex text-white">
                        <p className="mb-0 para-style">E</p> &nbsp;&nbsp;
                        <p className="mb-0 para-sub-style mt-lg-3">
                          Excellence to Trade and business Methods
                        </p>
                      </div>

                      <div className="d-flex text-white">
                        <p className="mb-0 para-style">Z</p> &nbsp;&nbsp;
                        <p className="mb-0 para-sub-style mt-lg-3">
                          Zone to Effective Business connectivity
                        </p>
                      </div>
                      <div className="d-flex text-white">
                        <p className="mb-0 para-style">I</p> &nbsp;&nbsp;
                        <p className="mb-0 para-sub-style mt-lg-3">
                          Infinite of Employment and Business Resources
                        </p>
                      </div>
                    </div>

                    <div className="position-set text-center">
                      <h1 className="mt-4 text-white font-setting">
                        We Are Coming Soon!
                      </h1>
                      <p className="text-white custom-res-text">
                        With a complete resource system for your business
                        development & growth.
                      </p>
                      <CountdownTimer />
                      <h4 className="text-white mt-3 mobile-font">
                        STAY TUNED WITH US
                      </h4>

                      <Col lg="2" md="4" sm="6" xs="6" className="mx-auto">
                        <div className="row">
                          <Col lg="3" md="3" sm="3" xs="3">
                            <AiFillTwitterCircle
                              size={40}
                              color="#49ff18"
                              className="cuursor_P"
                            />
                          </Col>
                          <Col lg="3" md="3" sm="3" xs="3">
                            <BiLogoFacebookCircle
                              size={40}
                              color="#49ff18"
                              className="cuursor_P"
                            />
                          </Col>
                          <Col lg="3" md="3" sm="3" xs="3">
                            <TiSocialLinkedinCircular
                              size={46}
                              color="#49ff18"
                              className="cuursor_P"
                            />
                          </Col>
                          <Col lg="3" md="3" sm="3" xs="3">
                            <GiEarthAfricaEurope
                              size={39}
                              color="#49ff18"
                              className="cuursor_P"
                            />
                          </Col>
                        </div>
                      </Col>
                      {/* <h5 className="text-white mt-2">Career With</h5> */}
                      <h5 className="text-white box" onClick={()=>navigate("/")}>
                       
                      
                          Selectezi
                       
                      </h5>
                      <h6 className="mt-3 text-poweredby">
                        Develop By{" "}
                        <a href="https://stmasters.com/" target="_blank">
                          {" "}
                          <span className="site-style">STMasters.com</span>{" "}
                        </a>
                      </h6>
                    </div>
                  </div>
                  <ReactPlayer
                    url={e.src}
                    width="100%"
                    pip={true}
                    muted={true}
                    playing={true}
                    loop={true}
                    preload="auto"
                    className="carousel-inner"
                    onReady={handleVideoLoad}
                    config={{
                      file: {
                        attributes: {
                          crossOrigin: "anonymous",
                        },
                      },
                    }}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Container>
      </Row>
    </>
  );
};

export default Wellcompage;
