import React, { useState, useEffect } from 'react';
import './timer.css';

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const projectDate = new Date('2024-11-15T00:00:00Z');
    const currentDate = new Date();
    const difference = projectDate - currentDate;

    if (difference < 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return {
      days,
      hours,
      minutes,
      seconds
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <div className="countdown-container">
      <div className="countdown-timer">
    
        <div className="countdown-item">
          <div className="countdown-value text-3d" >{`${timeLeft.days}:`}</div>
          <div className="countdown-label">days</div>
        </div>
        <div className="countdown-item">
          <div className="countdown-value text-3d" >{`${timeLeft.hours}:`}</div>
          <div className="countdown-label">hours</div>
        </div>
        <div className="countdown-item">
          <div className="countdown-value text-3d" >{`${timeLeft.minutes}:`}</div>
          <div className="countdown-label">minutes</div>
        </div>
        
        <div className="countdown-item">
          <div className="countdown-value text-3d">{timeLeft.seconds}</div>
          <div className="countdown-label ">seconds</div>
        </div>

      </div>
    </div>
  );
};

export default CountdownTimer;
