import Vid1 from "../videos/video1.mp4";
import img1 from "../assats/images/news-1.png";
import img2 from "../assats/images/news-2.png";
import img3 from "../assats/images/news-3.png";
import img4 from "../assats/images/news-4.png";
import img5 from "../assats/images/news-5.png";
import img6 from "../assats/images/news-6.png";
import img7 from "../assats/images/news-7.png";
import img8 from "../assats/images/news-8.png";
import img9 from "../assats/images/news-9.png";
import img10 from "../assats/images/news-10.png";
import img11 from "../assats/images/news-11.png";
import img12 from "../assats/images/news-12.png";
import img13 from "../assats/images/news-13.png";
import img14 from "../assats/images/news-14.png";
import img15 from "../assats/images/news-15.png";
import img16 from "../assats/images/news-16.png";
import img17 from "../assats/images/news-17.png";
import img18 from "../assats/images/news-18.png";
import img19 from "../assats/images/news-19.png";
import img20 from "../assats/images/news-20.png";
import img21 from "../assats/images/news-21.png";
import img22 from "../assats/images/news-22.png";
import img23 from "../assats/images/news-23.png";
import img24 from "../assats/images/news-24.png";
import img25 from "../assats/images/news-25.png";
import img26 from "../assats/images/news-26.png";
import img27 from "../assats/images/news-27.png";
import img28 from "../assats/images/news-28.png";
import img29 from "../assats/images/news-29.png";
import img30 from "../assats/images/news-30.png";
import img31 from "../assats/images/news-31.png";
import img32 from "../assats/images/news-32.png";
import img33 from "../assats/images/news-33.png";
import img34 from "../assats/images/news-34.png";
import img35 from "../assats/images/news-35.png";
import img36 from "../assats/images/news-36.png";
import img37 from "../assats/images/news-37.png";
import img38 from "../assats/images/news-38.png";
import img39 from "../assats/images/news-39.png";
import img40 from "../assats/images/news-40.png";
import img41 from "../assats/images/news-41.png";
import img42 from "../assats/images/news-42.png";
import img43 from "../assats/images/news-43.png";
import img44 from "../assats/images/news-44.png";
import img45 from "../assats/images/news-45.png";
import img46 from "../assats/images/news-46.png";
import img47 from "../assats/images/news-47.png";
import img48 from "../assats/images/news-48.png";
import img49 from "../assats/images/news-49.png";
import img50 from "../assats/images/news-50.png";
import img51 from "../assats/images/news-51.png";
import img52 from "../assats/images/news-52.png";
import img53 from "../assats/images/news-53.png";
import img54 from "../assats/images/news-54.png";
import img55 from "../assats/images/news-55.png";
import img56 from "../assats/images/news-56.png";
import img57 from "../assats/images/news-57.png";
import img58 from "../assats/images/news-58.png";
import img59 from "../assats/images/news-59.png";
import img60 from "../assats/images/news-60.png";
import img61 from "../assats/images/news-61.png";
import img62 from "../assats/images/news-62.png";
import img63 from "../assats/images/news-63.png";




export const videoprop=[
    {
id:1,
title:"video 1",
src:Vid1,
desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry."

    },

     
     
  ]

  export const newschannel=[

    {
      id:1,
      titleurl:"WWW.DAILYPAKISTAN.COM",
      name:"click to read complete new on our website. ",
      link:"https://www.dailypakistan.com.pk",
      img:img1
      
  },


  {
    id:2,
    titleurl:"WWW.PAKISTANTODAY.COM",
    name:"click to read complete new on our website. ",
    link:"https://www.pakistantoday.com.pk/",
    img:img2
    
},


{
  id:3,
  titleurl:"WWW.BBCNEWS.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.bbc.com/urdu",
  img:img3
  
},


{
  id:4,
  titleurl:"WWW.BOLNEWS.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.bolnews.com",
  img:img4

  
},

{
  id:5,
  titleurl:"WWW.92NEWSHD.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.92newshd.tv",
  img:img5
  
},

{
  id:6,
  titleurl:"WWW.DAWNNEWS.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.dawn.com",
  img:img6
  
},

{
  id:7,
  titleurl:"WWW.JANGNEWS.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.jang.com.pk",
  img:img7
  
},

{
  id:8,
  titleurl:"WWW.SAMANEWS.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.samaa.tv",
  img:img8

  
},

{
  id:9,
  titleurl:"WWW.AJJNEWS.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.aaj.tv",
  img:img9
  
},

{
  id:10,
  titleurl:"WWW.NEONETWORK.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.neonetwork.pk",
  img:img10
  
  
},

{
  id:11,
  titleurl:"WWW.GEOSUPER.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.geosuper.tv",
  img:img11
  
},

{
  id:12,
  titleurl:"WWW.DAILYJINNAH.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.dailyjinnah.com",
  img:img12
  
},


{
  id:13,
  titleurl:"WWW.ROZENEWS.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.rozenews.com.pk",
  img:img13
  
},


{
  id:14,
  titleurl:"WWW.MASHRIQTV.COM",
  name:"click to read complete new on our website. ",
  link:"https://mashriqtv.pk",
  img:img14
  
},


{
  id:15,
  titleurl:"WWW.SINDHTVNEWS.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.sindhtvnews.net",
  img:img15
  
},


{
  id:16,
  titleurl:"WWW.KHABRAIN.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.khabrain.com",
  img:img16
  
},


{
  id:17,
  titleurl:"WWW.ONLINEINDUS.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.onlineindus.com",
  img:img17
  
},


{
  id:18,
  titleurl:"WWW.JEHANPAKISTAN.COM",
  name:"click to read complete new on our website. ",
  link:"https://jehanpakistan.pk",
  img:img18
  
},

{
  id:19,
  titleurl:"WWW.HAMARIWEB.COM",
  name:"click to read complete new on our website. ",
  link:"https://enews.hamariweb.com",
  img:img19
  
},

{
  id:20,
  titleurl:"WWW.DAILYIBRAT.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.dailyibrat.com",
  img:img20
  
},

{
  id:21,
  titleurl:"WWW.TIMESOFPAKISTAN.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.timesofpakistan.com/",
  img:img21
  
},


{
  id:22,
  titleurl:"WWW.DAILYBAAKHABAR.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.dailybaakhabar.com",
  img:img22
  
},




    {
        id:23,
        titleurl:"WWW.NAWAIWAQT.COM",
        name:"click to read complete new on our website. ",
        link:"https://www.nawaiwaqt.com.pk",
        img:img23
        
    },

    {
        id:24,
        titleurl:"WWW.INP.COM",
        name:"click to read complete new on our website. ",
          link:"https://www.inp.net.pk",
          img:img24
        
    },

    {
        id:25,
        titleurl:"WWW.NAWAIWAQT.COM",
        name:"click to read complete new on our website. ",
          link:"https://www.nawaiwaqt.com.pk",
          img:img25
        
    },

    {
        id:26,
        titleurl:"WWW.QAUMIAKHBAR.COM",
        name:"click to read complete new on our website. ",
          link:"https://qaumiakhbar.com/",
          img:img26
        
    },

    {
        id:27,
        titleurl:"WWW.ABTAK.COM",
        name:"click to read complete new on our website. ",
          link:"www.abtak.com.pk",
          img:img27
        
    },

    {
        id:28,
        titleurl:"WWW.PINDIPOST.COM",
        name:"click to read complete new on our website. ",
          link:"https://www.pindipost.pk",
          img:img28
        
    },

    {
        id:29,
        titleurl:"WWW.NATION.COM",
        name:"click to read complete new on our website. ",
          link:"https://www.nation.com.pk",
          img:img29
        
    },

    {
        id:30,
        titleurl:"WWW.BRECORDER.COM",
        name:"click to read complete new on our website. ",
          link:"https://www.brecorder.com",
          img:img30
        
    },

    {
        id:31,
        titleurl:"WWW.URDUNEWS.COM",
        name:"click to read complete new on our website. ",
          link:"https://www.urdunews.com/",
          img:img31
        
    },



    {
        id:32,
        titleurl:"WWW.NIDAIMILLAT.COM",
        name:"click to read complete new on our website. ",
          link:"https://nidaimillat.nawaiwaqt.com.pk/E-Paper/lahore/2024-06-20",
          img:img32
        
    },












    {
      id:33,
      titleurl:"WWW.CRICKETPAKISTAN.COM",
      name:"click to read complete new on our website. ",
      link:"https://cricketpakistan.com.pk/",
      img:img33
      
  },


  {
    id:34,
    titleurl:"WWW.LHRTIMES.COM",
    name:"click to read complete new on our website. ",
    link:"https://www.lhrtimes.com",
    img:img34
    
},


{
  id:35,
  titleurl:"WWW.PKTRIBUNE.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.pktribune.com",
  img:img35
  
},


{
  id:36,
  titleurl:"WWW.AIKNEWSHD.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.aiknewshd.tv/",
  img:img36

  
},

{
  id:37,
  titleurl:"WWW.AKHBAREJHAN.COM",
  name:"click to read complete new on our website. ",
  link:"https://akhbar-e-jehan.com/",
  img:img37
  
},

{
  id:38,
  titleurl:"WWW.PAKOBSERVER.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.pakobserver.net",
  img:img38
  
},

{
  id:39,
  titleurl:"WWW.DAILYAJK.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.dailyajk.com",
  img:img39
  
},

{
  id:40,
  titleurl:"WWW.CHANNEL24.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.channel24.pk",
  img:img40

  
},

{
  id:41,
  titleurl:"WWW.DAILYMAIL.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.dailymail.co.uk/news/index.html",
  img:img41
  
},

{
  id:42,
  titleurl:"WWW.NAIBAAT.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.naibaat.pk",
  img:img42
  
  
},

{
  id:43,
  titleurl:"WWW.HAQAIQ.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.haqaiq.com",
  img:img43
  
},

{
  id:44,
  titleurl:"WWW.DAILYAUSAF.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.dailyausaf.com",
  img:img44
  
},


{
  id:45,
  titleurl:"WWW.THENEWS.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.thenews.com.pk",
  img:img45
  
},


{
  id:46,
  titleurl:"WWW.THEFRONTIERPOST.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.thefrontierpost.com",
  img:img46
  
},


{
  id:47,
  titleurl:"WWW.TRIBUNE.COM",
  name:"click to read complete new on our website. ",
  link:"https://tribune.com.pk/",
  img:img47
  
},


{
  id:48,
  titleurl:"WWW.DAILYSARZAMEEN.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.dailysarzameen.com",
  img:img48
  
},


{
  id:49,
  titleurl:"WWW.APP.COM",
  name:"click to read complete new on our website. ",
  link:"https://www.app.com.pk",
  img:img49
  
},


{
  id:50,
  titleurl:"WWW.NNINENEWS.COM",
  name:"click to read complete new on our website. ",
  link:"https://nni-news.com.pk/",
  img:img50
  
},

{
  id:51,
  titleurl:"WWW.PROPAKISTAN.COM",
  name:"click to read complete new on our website. ",
  link:"https://propakistani.pk/",
  img:img51
  
},

{
  id:52,
  titleurl:"WWW.SUBHENOE.COM",
  name:"click to read complete new on our website. ",
  link:"https://subhenoe.com/",
  img:img52
  
},

{
  id:53,
  titleurl:"WWW.CENTRELINE.COM",
  name:"click to read complete new on our website. ",
  link:"https://centreline.com.pk/",
  img:img53
  
},


{
  id:54,
  titleurl:"WWW.MINUTEMIRROR.COM",
  name:"click to read complete new on our website. ",
  link:"https://minutemirror.com.pk/e-paper/",
  img:img54
  
},

    {
        id:55,
        titleurl:"WWW.POSTNEWSPAPERS.COM",
        name:"click to read complete new on our website. ",
        link:"https://postnewspapers.com.au/",
        img:img55
        
    },

    {
        id:56,
        titleurl:"WWW.ALAKHBARNEWS.COM",
        name:"click to read complete new on our website. ",
          link:"https://alakhbar.com.pk/",
          img:img56
        
    },

    {
        id:57,
        titleurl:"WWW.URDUPOINT.COM",
        name:"click to read complete new on our website. ",
          link:"https://www.urdupoint.com/daily/",
          img:img57
        
    },

    {
        id:58,
        titleurl:"WWW.AKHBAREKHYBER.COM",
        name:"click to read complete new on our website. ",
          link:"https://www.akhbarekhyber.com/",
          img:img58
        
    },

    {
        id:59,
        titleurl:"WWW.FINANCIALDAILY.COM",
        name:"click to read complete new on our website. ",
          link:"https://thefinancialdaily.com/",
          img:img59
        
    },

    {
        id:60,
        titleurl:"WWW.HOTLINENEWS.COM",
        name:"click to read complete new on our website. ",
          link:"https://hotlinenews.pk/",
          img:img60
        
    },

    {
        id:61,
        titleurl:"WWW.DADYAL.COM",
        name:"click to read complete new on our website. ",
          link:"https://www.dadyal.com/",
          img:img61
        
    },

    {
        id:62,
        titleurl:"WWW.PTVNEWS.COM",
        name:"click to read complete new on our website. ",
          link:"https://ptv.com.pk/ptvNews",
          img:img62
        
    },

    {
        id:63,
        titleurl:"WWW.KMSNEWS.COM",
        name:"click to read complete new on our website. ",
          link:"https://kmsnews.org/kms/",
          img:img63
        
    },



   

    
  ]